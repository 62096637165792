exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accessibility-js": () => import("./../../../src/pages/accessibility.js" /* webpackChunkName: "component---src-pages-accessibility-js" */),
  "component---src-pages-book-lessons-js": () => import("./../../../src/pages/book-lessons.js" /* webpackChunkName: "component---src-pages-book-lessons-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-music-lessons-js": () => import("./../../../src/pages/music-lessons.js" /* webpackChunkName: "component---src-pages-music-lessons-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */)
}

